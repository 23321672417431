import DetailNavTitle from '@/components/business/detail-nav-title';
import React, {useCallback} from 'react';
import {debounce, getUUID, goBack, goTo, transfor} from '@/utils';
import {View} from 'react-native';
import theme from '@/style';
import Spin from '@/components/basic/spin';
import ProgressBar from './components/progress';
import ColorTime from './color-time-remaining';
import ColorQuick from './color-quick';
import ColorBalls from './color-ball';
import ColorBets from './color-bets';
import {Bets} from '@/components/business/games';
import {Times} from '@/components/business/games/times-list';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import {
  BetsInfo,
  ColorGetAllResponse,
  ColorGetLatestContentResponse,
  ColorGetNearestLotteryResponse,
  create,
  getAll,
  getLatest,
  getNearestLottery,
  getOrderByIssueNo,
  getOrderListColor,
} from './color-service';
import {BasicObject, SafeAny} from '@/types';
import ColorTab from './color-tabs/color-tab';
import RuleModal from '@/components/business/games/rule';
import ColorRule from './color-rules';
import ColorSaleRule from './color-sale-rule';
import Ball, {BallProps} from './components/ball';
import globalStore from '@/services/global.state';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import GoldWin from '@/components/business/games/gold-win';
import {checkIsShown, saveIssueNum} from '@/components/utils/gameWin';
import Video from '@/components/basic/video';
import {ScrollView} from 'react-native-gesture-handler';
import ColorTabView from './color-tabs/color-tab-veiw';
import PaidShareModal from '@/components/business/paid-share-modal';
import {getShareAward} from '@/services/global.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import {useTranslation} from 'react-i18next';

const ColorGame = () => {
  const {i18n} = useTranslation();
  const user = localStorage.getItem('user') || '{}';
  const userId = JSON.parse(user).userId;
  const {params = {}} = useRoute() as BasicObject;
  const {colorId} = params as {colorId?: number};
  const drawerRef = React.useRef<DrawerRef>(null);
  const [configs, setConfigs] = React.useState<ColorGetAllResponse[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [currentConfig, setCurrentConfig] =
    React.useState<ColorGetAllResponse | null>();
  const [currentLottery, setCurrentLottery] =
    React.useState<ColorGetNearestLotteryResponse>();
  const [latestLottery, setLatestLottery] =
    React.useState<ColorGetLatestContentResponse>();
  const [currentTime, setCurrentTime] = React.useState(-1);
  const [disabled, setDisabled] = React.useState(false);
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const [saleRuleVisible, setSaleRuleVisible] = React.useState(false);
  const [showWin, setShowWin] = React.useState(false);
  const [betsInfo, setBetsInfo] = React.useState<BetsInfo>();
  const lockToast = React.useRef(false);
  const [betsList, setBetsList] = React.useState<{
    [key: string]: BallProps[];
  }>({});
  const [winPaused, setWinPaused] = React.useState(true);
  const [winAmount, setWinAmount] = React.useState(0);
  const [refreshCode, setRefreshCode] = React.useState(0);
  const refreshCodeRef = React.useRef(0);
  const [cutdownTime, setCutdownTime] = React.useState(0);
  const [timeVersion, setTimeVersion] = React.useState(0);
  const {getState} = useNavigation<NavigationProp<any>>();
  const {index: stateIndex, routes} = getState();
  const totalOrder = React.useRef(0);
  const [orderNum, setOrderNum] = React.useState(0);
  const currentRouteName = routes[stateIndex].name;
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});
  const [popVisible, setPopVisible] = React.useState<boolean>(false);
  const [shareAward, setShareAward] = React.useState<number>(0);

  React.useEffect(() => {
    setLoading(true);
    getGameConfig().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAmount = useCallback(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next({gameType: 'Color'});
    }
  }, []);
  useFocusEffect(handleUpdateAmount);

  const getGameConfig = async () => {
    const res = await getAll();
    if (res) {
      setConfigs(res);
      if (colorId) {
        const find = res.find(item => item.id === Number(colorId));
        setCurrentConfig(find);
      } else {
        setCurrentConfig(res[0]);
      }
    }
  };

  React.useEffect(() => {
    if (currentConfig?.id) {
      getGameData(currentConfig.id);
    }
  }, [currentConfig]);

  const getGameData = async (configId: number) => {
    try {
      setLoading(true);
      const newest = await getNearestLottery({configId});
      if (newest) {
        setCurrentLottery(newest);
        // startRemaining(newest);
        setCutdownTime(() => newest.countdownTime);
        setTimeVersion(v => v + 1);
      }
      const latest = await getLatest({
        configId,
        pageNo: 1,
        pageSize: 1,
      });
      if (latest && latest.content) {
        setLatestLottery(latest.content[0]);
      }
    } finally {
      setLoading(false);
      setDisabled(false);
      lockToast.current = false;
    }
  };

  React.useEffect(() => {
    if (latestLottery) {
      getOpenResult(latestLottery.issueNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestLottery]);

  const getOpenResult = async (issueNo: string) => {
    try {
      const res = await getOrderByIssueNo(issueNo);
      if (res && res.awardAmount) {
        // 中奖
        const isShown = await checkIsShown('color', issueNo);
        if (!isShown) {
          setWinAmount(res.awardAmount);
          setShowWin(true);
          setWinPaused(false);
          globalStore.updateAmount.next({gameType: 'Color'});
          saveIssueNum('color', issueNo);
          getOrderListColor({
            orderStatus: '3',
            pageNo: 1,
            pageSize: 1,
          }).then(ret => {
            setThreeOrderData({
              userId,
              data: transfor({...ret?.content[0], userId}, 'color', {
                from: 'order',
              }),
            });
          });
        }
      }
    } finally {
    }
  };

  const updateColorPayOrder = (issNo: string, info: BallProps) => {
    const current = betsList[issNo] || [];
    current.push(info);
    const target = Object.assign({}, betsList, {[issNo]: [...current]});
    setBetsList(target);
  };

  const onBetsSubmit = async (totalPrice: number) => {
    try {
      if (!globalStore.token) {
        drawerRef.current?.close();
        goTo('Login');
        return;
      }
      if (disabled) {
        return;
      }
      if (betsInfo && currentLottery) {
        globalStore.globalLoading.next(true);
        const orderMsg = {
          issueNo: currentLottery?.issueNo,
          basePrice: totalPrice,
          num: 1,
          amount: totalPrice,
          selectType: betsInfo?.onlyColor ? 2 : 1,
          selectValue: betsInfo?.digit,
        };
        const res = await create(orderMsg);
        if (res) {
          if (!globalStore.imIcon) {
            globalStore.globalSucessTotal(i18n.t('paidSuccess.label.subTitle'));
          }
          const user = localStorage.getItem('user') || '{}';
          const userId = JSON.parse(user).userId;
          const shareUserId = localStorage.getItem('shareUserId');
          const [imStr, imGameCode] = (
            localStorage.getItem('PlayNow') || '-'
          ).split('-');
          if (shareUserId && imStr === 'im' && imGameCode === 'Color') {
            postReport({
              reportType: TReportType.BETS,
              gameName: `Color-${currentConfig!.cycle} Minutes`,
              gameType: 'color',
              userId,
              issueNo: orderMsg.issueNo,
              sourceType: TSourceType.IM,
              shareUserId,
            });
            localStorage.removeItem('PlayNow');
          }
          if (index !== 3) {
            totalOrder.current += 1;
            setOrderNum(totalOrder.current);
          }
          globalStore.updateAmount.next({gameType: 'Color'});
          drawerRef.current?.close();
          updateColorPayOrder(currentLottery?.issueNo, {
            digit: betsInfo.digit,
            color: betsInfo.color,
            onlyColor: betsInfo?.onlyColor,
          });
          getShareAward('color').then(award => {
            setShareAward(Math.floor(award));
            setPopVisible(true);
            getOrderListColor({
              orderStatus: '3',
              pageNo: 1,
              pageSize: 1,
            }).then(r => {
              setThreeOrderData({
                userId,
                data: transfor(orderMsg, 'color', {
                  rest: {
                    gameIconUrl: r?.content?.[0]?.gameIconUrl || '',
                    gameName: r?.content?.[0]?.gameName || '',
                    issNo: r?.content?.[0]?.issueNo || '',
                    openTime: r?.content?.[0]?.openTime || '',
                    totalAmount: orderMsg.amount,
                  },
                }),
              });
            });
          });
        }
      }
    } finally {
      globalStore.globalLoading.next(false);
    }
  };

  const onBetsSubmitCheck = debounce((totalPrice: number) => {
    onBetsSubmit(totalPrice);
  }, 200);

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    if (index === 3 && totalOrder.current) {
      totalOrder.current = 0;
      setOrderNum(0);
    }
  }, [index]);

  return (
    <View style={[theme.background.lightGrey, theme.flex.flex1]}>
      <DetailNavTitle
        title={'Color Prediction'}
        hideServer={true}
        onBack={() => {
          goBack();
          localStorage.removeItem('PlayNow');
        }}
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.basis0]}>
        <ScrollView stickyHeaderIndices={[6]}>
          {configs.length > 1 ? (
            <Times
              onTimePress={id => {
                const find = configs.find(pie => pie.id === id);
                setCurrentConfig(find);
              }}
              minutes={configs}
              configId={currentConfig?.id}
            />
          ) : (
            <View />
          )}
          <ColorTime
            digit={latestLottery?.digitResult}
            showRule={() => setRuleVisible(true)}
            countdownTime={cutdownTime}
            stopBetSec={currentLottery?.minRemainingSecond || 10}
            prevIssueNum={latestLottery?.issueNo}
            cycle={currentConfig?.cycle}
            issueNumber={currentLottery?.issueNo}
            isSameRoute={currentRouteName === 'Color'}
            onDisabled={() => {
              drawerRef.current?.close();
              setDisabled(true);
            }}
            timeVersion={timeVersion}
            onTimeEnd={() => {
              if (currentConfig?.id) {
                getGameData(currentConfig.id);
                refreshCodeRef.current += 1;
                setRefreshCode(refreshCodeRef.current);
              }
            }}
            onTimeChange={setCurrentTime}
          />
          <ProgressBar
            value={currentTime}
            total={currentLottery?.cycle ? currentLottery?.cycle * 60 : 1}
          />
          <ColorQuick
            disabled={disabled}
            onColorPress={info => {
              setBetsInfo(info);
              setTimeout(() => {
                drawerRef.current?.open();
              }, 0);
            }}
          />
          <ColorBalls
            disabled={disabled}
            onBets={info => {
              setBetsInfo(info);
              setTimeout(() => {
                drawerRef.current?.open();
              }, 0);
            }}
          />
          <ColorBets
            betsBall={
              currentLottery?.issueNo ? betsList[currentLottery?.issueNo] : []
            }
          />
          <ColorTab index={index} setIndex={setIndex} orderNum={orderNum} />
          <ColorTabView
            refreshCode={refreshCode}
            configId={currentConfig?.id!}
            index={index}
          />
        </ScrollView>
      </Spin>
      <PaidShareModal
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        shareAward={shareAward}
        sharInfo={{
          gameCode: 'color',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <Drawer
        beforeClose={() => {}}
        ref={drawerRef}
        mode="bottom"
        contentBackgroundColor="#0000">
        <Bets
          BallEle={
            <Ball
              ballSize={64}
              fontSize={betsInfo?.onlyColor ? 14 : 24}
              digit={betsInfo?.digit || ''}
              color={betsInfo?.color}
              fontColor={betsInfo?.onlyColor ? theme.basicColor.white : '#000'}
              onlyColor={Boolean(betsInfo?.onlyColor)}
            />
          }
          onBitSubmit={onBetsSubmitCheck}
          onSeeRule={() => setSaleRuleVisible(true)}
          onClose={() => drawerRef.current?.close()}
        />
      </Drawer>
      <RuleModal
        title="Rule"
        visible={ruleVisible}
        onClose={() => setRuleVisible(false)}>
        <ColorRule tabsInfo={configs} />
      </RuleModal>
      <RuleModal
        title="Sale rules"
        visible={saleRuleVisible}
        onClose={() => setSaleRuleVisible(false)}>
        <ColorSaleRule />
      </RuleModal>
      <GoldWin
        amount={winAmount}
        visible={showWin}
        shareAward={shareAward}
        onClose={() => {
          setShowWin(false);
        }}
        sharInfo={{
          gameCode: 'color',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <Video
        onEnd={() => {
          setWinPaused(true);
        }}
        audioOnly
        source={require('@components/assets/music/win.mp3')}
        src={require('@components/assets/music/win.mp3')}
        paused={winPaused}
      />
    </View>
  );
};

export default ColorGame;
